const axios = require("axios");

const baseUrl =
  process.env.REACT_APP_CORPSEAPP_API || "https://corpse.studio/corpseapi";
const baseImagesUrl = baseUrl + "/images";
const baseCorpsesUrl = baseUrl + "/corpses";

console.log("Using baseUrl", baseUrl);

function loadDrawing() {
  // Make a request for a user with a given ID
  return axios
    .get(baseImagesUrl + "/27")
    .then(function (response) {
      // handle success
      return response;
    })
    .catch(function (error) {
      // handle error
      //console.log(error);
    });
}

function updateDrawing(id, imageData) {
  return axios
    .put(baseImagesUrl, {
      data: imageData,
      id: id,
    })
    .then(function (response) {
      //console.log(response);
      return response;
    })
    .catch(function (error) {
      // console.log(error);
    });
}

function saveDrawing(imageData, corpseId, corpsePart) {
  return axios
    .post(baseImagesUrl, {
      data: imageData,
      name: "test",
      corpseId: corpseId,
      corpsePart: corpsePart,
    })
    .then(function (response) {
      //console.log(response);
      return response;
    })
    .catch(function (error) {
      //console.log(error);
    });
}

function loadCorpse(corpseId, corpseName) {
  //get corpse, get current drawing status --
  return axios
    .get(baseCorpsesUrl + "/" + corpseId + "/" + corpseName)
    .then(function (response) {
      // handle success
      //console.log("got this corpse response: ", response);
      //TODO: if drawing is complete, then just display the resulting corpse
      //TODO: otherwise return most recent drawing Id, so it can be brought in above new drawing
      return response;
    })
    .catch(function (error) {
      // handle error
      //console.log(error);
    });
}

function addNewCorpse(imageData) {
  return axios
    .post(baseCorpsesUrl, {})
    .then(function (response) {
      //console.log(response);
      return response;
    })
    .catch(function (error) {
      //console.log(error);
    });
}

export { saveDrawing, loadDrawing, updateDrawing, addNewCorpse, loadCorpse };
