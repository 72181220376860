//import { render } from "@testing-library/react";
import React, { Component } from "react";
import CanvasDraw from "react-canvas-draw";
import { useParams } from "react-router";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { saveDrawing, updateDrawing, addNewCorpse, loadCorpse } from "./api";
import penLogo from "./images/pen.svg";
import eraserLogo from "./images/erase.svg";
import undoLogo from "./images/undo.svg";
import clearLogo from "./images/clear.svg";

import "./App.css";

function determineNextDrawing(resp) {
  let nextDrawing = null;
  let prevData = null;
  if (
    resp.data.imageData === null ||
    resp.data.imageData === undefined ||
    resp.data.imageData[0].imagestring === null
  ) {
    nextDrawing = "Head";
    prevData = null;
    return { nextDrawing: "Head", prevData: null };
  } else if (resp.data.imageData.length === 1) {
    nextDrawing = "Torso";
    prevData = resp.data.imageData[0]; // get data from array
  } else if (resp.data.imageData.length === 2) {
    nextDrawing = "Feet";
    prevData = resp.data.imageData[1]; // get data from array
  } else if (resp.data.imageData.length > 2) {
    nextDrawing = "End";
  }

  //console.log("next image should be: ", nextDrawing);

  return { nextDrawing: nextDrawing, prevData: prevData };
}

function loadCorpseAndState(corpseId, corpseName) {
  // console.log("given: ", corpseId, corpseName);
  return loadCorpse(corpseId, corpseName).then((resp) => {
    return resp;
  });
}

function copyLastDrawingToSample(reactContext, prevDrawing) {
  const smallCanvas = reactContext.smallerCanvas.current;
  const ctx = smallCanvas.getContext("2d");
  var image = new Image();
  image.src = prevDrawing;

  image.onload = function () {
    ctx.drawImage(image, 0, 375, 800, 20, 0, 0, 800, 20);
  };

  if (prevDrawing !== null) {
    image.src = prevDrawing;
  }
}

function getCorpseUrl(corpseName, corpseId) {
  return `/id/${corpseId}/corpse/${corpseName}`;
}

function CurrentCorpse() {
  let { corpseid, corpsename } = useParams();

  return (
    <div className="App">
      <Exquisitor
        canvasWidth="80%"
        canvasHeight="600px"
        lazyRadius="1"
        brushRadius="1"
        hideInterface="false"
        corpseId={corpseid}
        corpseName={corpsename}
      />
      <div id="drawingArea" className="canvasHolder"></div>
    </div>
  );
}

function copyAllImagesToCanvas(reactContext, corpseData) {
  // console.log("corpse data", corpseData);
  const fullCanvas = reactContext.fullCanvas.current;
  const ctx = fullCanvas.getContext("2d");
  ctx.scale(1, 1);
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, 800, 1200);
  const headData = corpseData.data.imageData[0].imagestring.replace(
    /['"]+/g,
    ""
  );
  const torsoData = corpseData.data.imageData[1].imagestring.replace(
    /['"]+/g,
    ""
  );
  const footData = corpseData.data.imageData[2].imagestring.replace(
    /['"]+/g,
    ""
  );

  var headImage = new Image();
  headImage.src = headData;
  headImage.onload = function () {
    ctx.drawImage(headImage, 0, 0);
  };

  var torsoImage = new Image();
  torsoImage.src = torsoData;
  torsoImage.onload = function () {
    ctx.drawImage(torsoImage, 0, 400);
  };

  var footImage = new Image();
  footImage.src = footData;

  footImage.onload = function () {
    ctx.drawImage(footImage, 0, 800);
  };
}

function downloadFinalImage(reactContext) {
  var download = document.getElementById("download");
  var image = reactContext.fullCanvas.current.toDataURL("image/png");
  download.setAttribute("href", image);
}

function getBannerInstructions(part) {
  var bannerBlurb = "";
  if (part === "Head") {
    bannerBlurb =
      "Draw a HEAD in the rectangle below. When you're done, click 'Save This Section'. Then send the resulting link to a friend.";
  } else if (part === "Torso") {
    bannerBlurb =
      "You've been invited to draw a TORSO in the rectangle below. When you're done, click 'Save This Section'. Then send the resulting link to a friend.";
  } else if (part === "Feet") {
    bannerBlurb =
      "You've been invited to draw a LOWER TORSO in the rectangle below. When you're done, click 'Save This Section'.";
  }
  return bannerBlurb;
}

function InstructionsBlurb(props) {
  var lowestBlurbText =
    "<-- Make sure your drawing touches the bottom of the canvas.";
  var upperBlurb = "";
  var lowerBlurb = "";
  var marginLower = "0%";
  var marginUpper = "0%";
  var headImage = require("./thumbnailhead.png");
  var torsoImage = require("./thumbnailtorso.png");
  var feetImage = require("./thumbnailfeet.png");
  let imageToShow = headImage;
  if (props.part === "Head") {
    upperBlurb = "";
    lowerBlurb = lowestBlurbText;
    marginUpper = "";
    marginLower = "40%";
    imageToShow = headImage;
  } else if (props.part === "Torso") {
    upperBlurb =
      "<-- Note that a sample of the previous image is shown just above your drawing area. ";
    lowerBlurb = lowestBlurbText;
    marginUpper = "-30%";
    marginLower = "30%";
    imageToShow = torsoImage;
  } else if (props.part === "Feet") {
    upperBlurb =
      "<-- Note that a sample of the previous image is shown just above your drawing area. ";
    lowerBlurb = "";
    marginUpper = "-30%";
    marginLower = "30%";
    imageToShow = feetImage;
  }
  return (
    <div className="blurb">
      <div style={{ marginTop: marginUpper }}>{upperBlurb}</div>
      <div style={{ marginTop: "50%" }}>
        <img src={imageToShow} alt="" />
      </div>
      <div style={{ marginTop: marginLower }}>{lowerBlurb}</div>
    </div>
  );
}

//display the whole thing
class FullCorpse extends Component {
  constructor(props) {
    super(props);
    this.fullCanvas = React.createRef();
  }

  componentDidMount() {
    copyAllImagesToCanvas(this, this.props.corpseData);
  }

  render() {
    /// create one big canvas, load data from each image into three separate portions
    return (
      <div className="fullCorpseDiv">
        <div className="appBanner">Here is your corpse! It is exquisite!</div>
        <div className="fullCorpseMenu">
          <button
            className="standardButton"
            onClick={() => {
              addNewCorpse().then((res) => {
                window.location.replace(
                  `/id/${res.data.id}/corpse/${res.data.name}/`
                );
              });
            }}
          >
            Start a new corpse!
          </button>

          <a id="download" download="ExquisiteCorpseImage.png" href="#">
            <button
              className="standardButton"
              type="button"
              onClick={() => {
                downloadFinalImage(this);
              }}
            >
              Download This Corpse
            </button>
          </a>

          <a id="shareLink" href="#">
            <button
              id="copyURLBtn"
              className="standardButton"
              type="button"
              onClick={() => {
                var ackSpan = document.getElementById("copiedAck");
                copyUrlToClipboard(window.location.href, ackSpan);
              }}
            >
              Copy URL to clipboard
            </button>
          </a>
          <span id="copiedAck"></span>

          <input
            type="text"
            id="shareUrl"
            style={{ height: "1px", width: "1px", visibility: "hidden" }}
            value={this.props.corpseData.config.url}
          ></input>
        </div>
        <canvas
          id="fullCanvas"
          ref={this.fullCanvas}
          width="800px"
          height="1200px"
          style={{
            background: "#ffffff",
            border: "10px black solid",
          }}
        />
      </div>
    );
  }
}

function StartPage() {
  return (
    <div className="App">
      <div className="appBanner">make yourself an exquisite corpse!</div>
      <div className="smallnessOverlay">
        <div className="smallnessBlurb">
          <span>Your screen width is too small to work with this site.</span>
          <span>
            Try either rotating your device to use landscape mode, or use this
            site from a larger device.
          </span>
        </div>
      </div>
      <div>
        <button
          className="standardButton"
          onClick={() => {
            addNewCorpse().then((res) => {
              window.location.replace(
                `/id/${res.data.id}/corpse/${res.data.name}/`
              );
            });
          }}
        >
          Start a new corpse!
        </button>
      </div>

      {/* 
      TODO -- add some things
      <div>How to use this app</div>

      <div>FAQ</div>

      <div>See some finished corpses</div> */}
    </div>
  );
}

function ShareBox(props) {
  var shareText = "Share this URL with the next artist:";
  if (props.currentPart === "Feet" && props.wasSaved) {
    return (
      <div>
        <div className="shareBlurb">
          <a href={props.urlToShare}>
            <button
              className="standardButton"
              onClick="'window.location.href=' + props.urlToShare"
            >
              You're Done! Click here to see your Exquisite creation!
            </button>
          </a>
        </div>
      </div>
    );
  } else if (props.wasSaved) {
    return (
      <div>
        <div className="shareBlurb">{shareText}</div>
        <div>
          <input type="text" id="shareUrl" value={window.location.href} />
        </div>
        <button
          className="standardButton"
          onClick={() => {
            window.location.reload();
          }}
        >
          Next Section
        </button>

        <button
          className="standardButton"
          onClick={(whatisthis) => {
            var ackDiv = document.getElementById("ackDiv");
            copyUrlToClipboard(window.location.href, ackDiv);
          }}
        >
          Copy URL to clipboard
        </button>
        <span id="ackDiv"></span>
        <a
          href={
            "mailto:?body=You have been invited to participate in a drawing of an exquisite corpse!\n\r\n\r%0D%0A\n\r\n\r%0D%0A" +
            "Follow this exquisite corpse link to draw your portion:  \n\r\n\r%0D%0A" +
            window.location.href +
            "&subject=Please Contribute To This Exquisite Corpse"
          }
        >
          <button className="standardButton" type="button" onClick={() => {}}>
            Mail to a Friend
          </button>
        </a>
      </div>
    );
  } else {
    return <div></div>;
  }
}

function copyUrlToClipboard(url, ackDiv) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(url).then(function () {
      ackDiv.innerText = "Copied!";
    }, console.log("Could not copy Url to clipboard.", url));
  } else {
    var shareInput = document.getElementById("shareUrl");
    shareInput.select();
    shareInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }
}

class Exquisitor extends Component {
  state = {
    corpsePart: null,
    nextUrl: null,
    color: "#333333",
    width: 800,
    height: 400,
    brushRadius: 1,
    lazyRadius: 0,
    wasSaved: false,
    saveLabel: "Save",
    selectedTool: "Pen",
    drawingId: null,
    corpseId: this.props.corpseId,
    corpseName: this.props.corpseName,
    corpseLoaded: false,
    corpseData: null,
  };

  constructor(props) {
    super(props);
    this.smallerCanvas = React.createRef();
    this.shareUrl = React.createRef();
    this.wasSaved = false;
    this.drawingId = null;
    this.drawingHash = null;
    this.saveLabel = "Save?";
    this.nextUrl = null;
    this.corpseData = loadCorpseAndState(
      this.state.corpseId,
      this.state.corpseName
    );
  }

  componentDidMount() {
    loadCorpseAndState(this.state.corpseId, this.state.corpseName).then(
      (res) => {
        const nextDrawingData = determineNextDrawing(res);
        const nextDrawing = nextDrawingData.nextDrawing;
        this.setState({ corpseData: res, corpsePart: nextDrawing });

        if (nextDrawingData.prevData !== null) {
          const prevImage = nextDrawingData.prevData.imagestring;
          copyLastDrawingToSample(this, JSON.parse(prevImage));
        }
      }
    );
  }

  render() {
    var previewCanvasClass = "previewCanvas";
    if (this.state.corpsePart === "Head") {
      previewCanvasClass = "hiddenPreview";
    }

    if (this.state.corpsePart !== "End") {
      return (
        <div className="drawContainer">
          <div className="smallnessOverlay">
            <div className="smallnessBlurb">
              <span>
                Your screen width is too small to work with this site.
              </span>
              <span>
                Try either rotating your device to use landscape mode, or use
                this site from a larger device.
              </span>
            </div>
          </div>
          <div className="appBanner">make yourself an exquisite corpse!</div>
          <div className="lowerBanner">
            {getBannerInstructions(this.state.corpsePart)}
          </div>
          <div className="canvasContainer">
            <div className={previewCanvasClass}>
              <canvas
                id="trimmedhead"
                style={{ background: "beige" }}
                width="800px"
                height="20px"
                ref={this.smallerCanvas}
              ></canvas>
            </div>

            <div className="theActualCanvas">
              <CanvasDraw
                id="canvasDraw"
                ref={(canvasDraw) => (this.saveableCanvas = canvasDraw)}
                hideGrid={true}
                className="canvasDrawComponent"
                brushColor={this.state.color}
                brushRadius={this.state.brushRadius}
                lazyRadius={this.state.lazyRadius}
                canvasWidth={this.state.width}
                canvasHeight={this.state.height}
                catenaryColor={
                  this.state.selectedTool === "Pen" ? "#000000" : "#ff0000"
                }
              />
            </div>
            <div className="canvasControls">
              <button
                title="Pen"
                className={`paletteButton ${
                  this.state.selectedTool === "Pen" ? "selectedUtensil" : ""
                }`}
                onClick={() => {
                  this.setState({
                    selectedTool: "Pen",
                    color: "#000000",
                    brushRadius: 1,
                  });
                }}
              >
                <img class="drawingControlIcon" src={penLogo} alt="pen" />
              </button>

              <button
                title="Eraser"
                className={`paletteButton ${
                  this.state.selectedTool === "Eraser" ? "selectedUtensil" : ""
                }`}
                onClick={() => {
                  this.setState({
                    selectedTool: "Eraser",
                    color: "#ffffff",
                    brushRadius: 10,
                  });
                }}
              >
                <img class="drawingControlIcon" src={eraserLogo} alt="erase" />
              </button>

              <button
                title="Undo"
                onClick={() => {
                  var theCanvas = this.saveableCanvas;
                  theCanvas.undo();
                }}
                className="paletteButton"
              >
                <img class="drawingControlIcon" src={undoLogo} alt="undo" />
              </button>

              <button
                title="Clear"
                className="paletteButton"
                onClick={() => {
                  var theCanvas = this.saveableCanvas;
                  theCanvas.clear();
                }}
              >
                <img class="drawingControlIcon" src={clearLogo} alt="clear" />
              </button>
            </div>

            <InstructionsBlurb part={this.state.corpsePart} />
          </div>

          <div className="paletteBox">
            <button
              className="standardButton"
              onClick={() => {
                var drawingData = this.saveableCanvas.canvas.drawing.toDataURL();

                if (this.state.drawingId > 0) {
                  //console.log("updating the drawing");
                  updateDrawing(this.state.drawingId, drawingData);
                  this.setState({
                    nextUrl: getCorpseUrl(
                      this.state.corpseName,
                      this.state.corpseId
                    ),
                  });
                } else {
                  saveDrawing(
                    drawingData,
                    this.state.corpseId,
                    this.state.corpsePart
                  ).then((res) => {
                    this.setState({
                      nextUrl: getCorpseUrl(
                        this.state.corpseName,
                        this.state.corpseId
                      ),
                    });

                    this.setState({ drawingId: res.data.id });
                    this.setState({ drawingHash: res.data.name });
                    this.setState({ saveLabel: "Update" });
                    this.setState({ wasSaved: true });
                  });
                }
              }}
            >
              {this.state.saveLabel} this section
            </button>
          </div>

          <ShareBox
            wasSaved={this.state.wasSaved}
            urlToShare={this.state.nextUrl}
            currentPart={this.state.corpsePart}
          />
        </div>
      );
    }
    return (
      <div>
        <FullCorpse corpseData={this.state.corpseData} />
      </div>
    );
  }
}

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path="/id/:corpseid/corpse/:corpsename"
          children={<CurrentCorpse />}
        />

        <Route path="/" children={<StartPage />} />
      </Switch>
    </Router>
  );
}

export default App;
